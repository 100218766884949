import SEO        from "components/seo"
import React      from "react"
import {NewsList} from "../sections/News"

const News = () => {
  return (
    <>
      <SEO
        title="Aktualności"
        description={
          "Świat Bez Barier to fundacja dla dzieci, która od czerwca 2020 roku zapewnia lepszy start w dorosłość."
        }
      />
      <NewsList/>
    </>
  )
}

export default News
